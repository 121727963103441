import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireFunctions } from '@angular/fire/functions';
import { DataService } from '../data.service';
import { ToastService } from '../shared/toast.service';

@Component({
  selector: 'app-unternehmen',
  templateUrl: './unternehmen.component.html',
  styleUrls: ['./unternehmen.component.scss'],
})
export class UnternehmenComponent implements OnInit {
  check = false;

  loading = false;
  public kontaktValue = "";

  platformID;

  showUserId = false;
  clickCount = 0;

  userId;

  constructor(
    // @Inject(PLATFORM_ID) private platformID: Object,
    private angularFireFunctions: AngularFireFunctions,
    private toastService: ToastService,
    public DataService: DataService,
  ) {}

  ngOnInit(): void {
    window.location.href = "https://app.karriereheld.team/register/unternehmen?kref=betaunternehmen";
  }

  openInstagram() {
    if (!this.DataService.isKioskTablet) {
      window.open("https://instagram.com/karriereheld.team", "_blank");
    }
  }

  teilnehmenButton = [
    {
      id: "teilnehmen",
      name: "Teilnehmen",
      iconClass: "far fa-paper-plane",
      onClick: () => {
        this.teilnehmen();
      },
    },
  ];

  instagramButton = [
    {
      id: "insta",
      name: "Instagram öffnen",
      iconClass: "fab fa-instagram",
      onClick: () => {
        this.openInstagram();
      },
    },
  ];

  teilnehmenButtonLoading = [
    {
      id: "teilnehmen",
      name: "Senden...",
      iconClass: "far fa-spinner-third fa-spin",
    },
  ];

  websiteButton = [
    {
      id: "website",
      name: "Website öffnen",
      iconClass: "far fa-globe",
      onClick: () => {
        window.open("https://karriereheld.team?kref=beta");
      },
    },
  ];

  teilnehmenButtonCheck = [
    {
      id: "teilnehmen",
      name: "Du nimmst teil",
      iconClass: "far fa-check",
    },
  ];

  acceptNewsletter

  async teilnehmen() {
    if(!this.DataService.isKioskTablet && !this.acceptNewsletter ){
      this.toastService.error({ title: 'Die Datenschutzbestimmungen müssen akzeptiert werden.' });
      return
    }
    this.loading = true;
    const enter = await this.angularFireFunctions
      .httpsCallable("enterBetaUnternehmen")({
        mail: this.kontaktValue,
      })
      .toPromise()
      .catch((err) => {
        console.log("error", err);
        this.loading = false;
        this.toastService.error({ title: err });
      });
    this.loading = false;
    if (enter.error) {
      this.toastService.error({ title: enter.error });
      if (enter.error == "Du hast bereits teilgenommen") {
        this.check = true;
      }
    } else if (enter.success) {
      this.toastService.success({
        title: "Du wurdest eingetragen. Vielen Dank",
      });
      if(this.DataService.isKioskTablet){
        this.kontaktValue = ''
      }
      this.check = true;
    }
    console.log("TEILNEHMEN", enter);
  }
}
