import { NgModule } from "@angular/core";
import { Routes, RouterModule, NoPreloading } from "@angular/router";
import { PrivacyAgbComponent } from "./privacy/privacy-agb/privacy-agb.component";
import { PrivacyImprintComponent } from "./privacy/privacy-imprint/privacy-imprint.component";
import { PrivacyParticipationComponent } from "./privacy/privacy-participation/privacy-participation.component";
import { environment } from "src/environments/environment";
import {UnternehmenComponent}from './unternehmen/unternehmen.component'
import { BetaComponent } from "./beta/beta.component";

const routes: Routes = [
  {
    path: "",
    component: BetaComponent,
  },
  {
    path: "agb",
    component: PrivacyAgbComponent,
  },
  {
    path: "imprint",
    component: PrivacyImprintComponent,
  },
  {
    path: "participation",
    component: PrivacyParticipationComponent,
  },
  {
    path: "unternehmen",
    component: UnternehmenComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
