<ion-content>
  <div class="logoContainer">
    <img id="logo" style="padding-top: 10rem; width: 200px;" src="/assets/logo/logo_grey.svg" />
    <p *ngIf="showUserId" style="font-size: 8pt">{{ userId }}</p>
  </div>
  <!-- <ion-grid>

    <div style="padding-top: 50px" class="text-center">
      <ion-row>
        <ion-col size="12" sizeMd="10" offsetMd="1" style="line-height: 1.5">
          <h1>
            Du bist Student, Young Professional, Expert, Senior Professional
            oder Teamleader?
          </h1>
          <p>Nimm jetzt an der Beta teil.</p>
        </ion-col>

        <ion-col size="12" sizeMd="10" offsetMd="1" style="line-height: 1.5">
          <ion-input
            placeholder="Email | Telefonnummer | Instagram"
            [(ngModel)]="kontaktValue"
            #kontakt
            type="email"
            (keydown.enter)="teilnehmen()"
            (keydown.tab)="teilnehmen()"
          >
          
          </ion-input>

          <p *ngIf="!DataService.isKioskTablet">
            <ion-checkbox
              color="primary"
              [(ngModel)]="acceptNewsletter"
            ></ion-checkbox
            >&nbsp;Ich akzeptiere die <a href="https://karriereheld.team/privacy" target="_blank">Datenschutzbestimmungen</a>
          </p>
          <ng-container *ngIf="!loading && (DataService.isKioskTablet || !check)">
            <ui-select [items]="teilnehmenButton"></ui-select>
          </ng-container>
          <ui-select
          *ngIf="loading"
          [items]="teilnehmenButtonLoading"
        ></ui-select>
          <ui-select
            *ngIf="!DataService.isKioskTablet && check && !loading"
            [items]="teilnehmenButtonCheck"
          ></ui-select>
        </ion-col>

        <ion-col
          size="12"
          style="display: flex; justify-content: center; margin-top: 48px"
        >
          <div class="selectContainer" *ngIf="DataService.isKioskTablet">
            <div class="selectItem" style="background-color: white !important">
              <img
                width="90%"
                style="margin: 0 auto"
                src="/assets/img/QRCode_Insta.jpg"
                (click)="openInstagram()"
              />
            </div>
          </div>

          <ui-select
            *ngIf="!DataService.isKioskTablet"
            [items]="instagramButton"
          ></ui-select>

          <ui-select
            *ngIf="!DataService.isKioskTablet"
            [items]="websiteButton"
          ></ui-select>
        </ion-col>

      </ion-row>
    </div>
  </ion-grid> -->
</ion-content>
